<template>
    <template v-if="hasGroupedColumns">
        <div v-if="!disableSearch" class="input-group mb-2" style="min-height: 38px;">
            <input type="search" class="form-control from-control-sm" @input = "getColumns" :placeholder="$t('Search...')" v-model="search">

            <div class="input-group-text">
                <input class="form-check-input mt-0" v-model="selectAllColumns" type="checkbox" :indeterminate="someColumnsSelected && !allColumnsSelected"
                    :title="allColumnsSelected ? $t('Hide All Columns') : $t('Choose All Columns')">
            </div>
             <button v-if="previousColumnsStates.length > 0" class="btn btn-outline-primary btn-sm" :title="$t('Undo selection')" 
                @click="resetToPreviousState" type="button">
                <i class="bi bi-arrow-counterclockwise"></i>
            </button>
        </div>
        <slot name="beforeList"></slot>
        <div style="overflow:auto; min-height: 24px;">
            <template v-for="(col, colIndex) in columnGroups" :key="col.colId ?? col.groupId?? col.uid">
                <template v-if="col.groupId">
                    <div class="w-100 border-bottom">
                    <div class="form-check ms-1 column-chooser-item">
                        <input class="form-check-input" type="checkbox" v-model="col.shown" :id="'columnchooser_'+(col.colId??col.groupId)+id">
                        <label class="form-check-label d-inline" :for="'columnchooser_'+(col.colId??col.groupId)+id" :title="getTitle(col)">
                            {{col.caption ?? col.field}}
                        </label>
                        <label v-if="col.required" :style="{ 'font-weight': 'bold' }">
                            *
                        </label>
                    </div>
                    <br>
                    <div v-for="child in col.children" :key="child.name" class="form-check ms-1 column-chooser-item ms-4">
                        <input class="form-check-input" type="checkbox" v-model="child.shown" :id="'columnchooser_'+child.name+id">
                        <label class="form-check-label d-inline" :for="'columnchooser_'+child.name+id" :title="getTitle(col)">
                            {{child.caption ?? col.field}}
                        </label>
                        <label v-if="col.required" :style="{ 'font-weight': 'bold' }">
                            *
                        </label>
                    </div>
                    </div>
                </template>

                <div v-else class="form-check ms-1 column-chooser-item">
                    <input class="form-check-input" type="checkbox" v-model="col.shown" :id="'columnchooser_'+col.colId+id">
                    <label class="form-check-label d-inline" :for="'columnchooser_'+col.colId+id" :title="getTitle(col)">
                        {{col.caption ?? col.field}}
                    </label>
                    <label v-if="col.required" :style="{ 'font-weight': 'bold' }">
                        *
                    </label>
                </div>
                
            </template>
        </div>
    </template>
    <template v-else>
        <div v-if="!disableSearch" class="input-group mb-2" style="min-height: 38px;">
            <input type="search" class="form-control from-control-sm" @input = "getColumns" :placeholder="$t('Search...')" v-model="search">
            <div class="input-group-text">
                <button class="btn btn-sm btn-link ps-0 py-0" @click="() => {highlightSearch = !highlightSearch; getColumns(); }" 
                    :title="highlightSearch ? $t('Hide columns not in search results') : $t('Highlight search results while still showing all columns')">
                    <i :class="highlightSearch ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
                </button>
                <input class="form-check-input mt-0" v-model="selectAllColumns" type="checkbox" :indeterminate="someColumnsSelected && !allColumnsSelected"
                    :title="allColumnsSelected ? $t('Hide All Columns') : $t('Choose All Columns')">
            </div>
            <button v-if="previousColumnsStates.length > 0" class="btn btn-outline-primary btn-sm" :title="$t('Undo selection')" 
                @click="resetToPreviousState" type="button">
                <i class="bi bi-arrow-counterclockwise"></i>
            </button>
        </div>
        <slot name="beforeList"></slot>

        <div class="text-muted min-h-unset" v-if="hasLeftPinned">{{ $t('Left pinned:') }}</div>
        <div :ref="leftSortableContainerFn" class="ms-1 border-bottom mb-1" v-if="hasLeftPinned" style="overflow:auto; min-height: 24px;">
            <template v-for="col in columnsList" :key="col.colId">
                <div v-if="col.pinned === 'left'" class="form-check column-chooser-item text-truncate" :class="{'result': col._column_chooser}" :o365-column-chooser-field="col.colId">
                    <input class="form-check-input ms-1" type="checkbox" v-model="col.shown" :id="'columnchooser_'+col.colId+id">
                    <label class="form-check-label d-inline ms-1" :for="'columnchooser_'+col.colId+id" :title="getTitle(col)">
                        {{$t(col.caption ?? col.field)}}
                    </label>
                    <label v-if="col.required" :style="{ 'font-weight': 'bold' }">
                        *
                    </label>
                </div>
            </template>
        </div>

        <div class="text-muted min-h-unset" v-if="hasLeftPinned || hasRightPinned">{{ $t('Unpinned:') }}</div>
        <div :ref="centerSortableContainerFn" style="overflow:auto; min-height: 24px;" class="ms-1">
            <template v-for="col in columnsList" :key="col.colId">
                <div v-if="!col.pinned" class="form-check column-chooser-item text-truncate" :class="{'result': col._column_chooser}" :o365-column-chooser-field="col.colId" >
                    <input class="form-check-input ms-1" type="checkbox" v-model="col.shown" :id="'columnchooser_'+col.colId+id">
                    <label class="form-check-label d-inline ms-1" :for="'columnchooser_'+col.colId+id" :title="getTitle(col)">
                        {{$t(col.caption ?? col.field)}}
                    </label>
                    <label v-if="col.required" :style="{ 'font-weight': 'bold' }">
                        *
                    </label>
                </div>
            </template>
        </div>

        <div class="text-muted min-h-unset border-top" v-if="hasRightPinned">{{ $t('Right pinned:') }}</div>
        <div :ref="rightSortableContainerFn" class="ms-1 mt-1" v-if="hasRightPinned" style="overflow:auto; min-height: 24px;">
            <template v-for="col in columnsList" :key="col.colId">
                <div v-if="col.pinned === 'right'" class="form-check column-chooser-item text-truncate" :class="{'result': col._column_chooser}" :o365-column-chooser-field="col.colId" >
                    <input class="form-check-input ms-1" type="checkbox" v-model="col.shown" :id="'columnchooser_'+col.colId+id">
                    <label class="form-check-label d-inline ms-1" :for="'columnchooser_'+col.colId+id" :title="getTitle(col)">
                        {{col.caption ?? col.field}}
                    </label>
                    <label v-if="col.required" :style="{ 'font-weight': 'bold' }">
                        *
                    </label>
                </div>
            </template>
        </div>

    </template>
    <div class="border-top chooser-buttons pt-2" v-if="false && gridRef">
        <button v-if="allColumnsSelected" class="btn  btn-outline-primary btn-sm" @click="chooseAllColumns(false)">
            <i class="bi bi-check-all"></i>
            {{$t('Hide All Columns')}}
        </button>
        <button v-else class="btn  btn-outline-primary btn-sm" @click="chooseAllColumns(true)">
            <i class="bi bi-check-all"></i>
            {{$t('Choose All Columns')}}
        </button>
    </div>
</template>

<script setup>
import DataColumnGroup from 'o365.controls.DataGrid.ColumnGroup.ts';
import DataColumn from 'o365.controls.DataGrid.Column.ts';
import { toRef, ref, computed, watch, onUpdated } from 'vue';

import Sortable from 'sortable';

const search = ref("");
const id = Math.round(Math.random() * 1000);

const props = defineProps({
    gridRef: null,
    columns: null,
    disableSearch: Boolean,
    dataColumns: null,
    watchTarget: null
});

let leftSortable = null;
let centerSortable = null;
let rightSortable = null;
const leftSortableContainerFn = (el) => {
    if (leftSortable) { leftSortable.destroy(); }
    leftSortable = getSortable(el, 'left');
};
const centerSortableContainerFn = (el) => {
    if (centerSortable) { centerSortable.destroy(); }
    centerSortable = getSortable(el);

}
const rightSortableContainerFn = (el) => {
    if (rightSortable) { rightSortable.destroy(); }
    rightSortable = getSortable(el, 'right');

};

const columnPredicate = (col) => {
    return !col.colId?.startsWith("o365") && !col.hideFromChooser;
};

const highlightSearch = ref(true);

const hasGroupedColumns = (props.gridRef?.dataColumns?.hasGroupedColumns || props.dataColumns?.hasGroupedColumns) ?? false;
const columnGroups = computed(() => { 
    const searchValue = search.value.toLowerCase();
    return ((props.columns ?? props.gridRef?.dataColumns.columns).reduce((arr, col) => {
        const colDisplayName = (col.headerName ?? col.name)?.toLowerCase();
        const includeColumn = searchValue.length === 0 || colDisplayName.includes(searchValue);
    
        if (!col.parentGroupId) {
            /* if (props.columns && includeColumn) { arr.push(new DataColumn(col)); }
            if (!props.columns && includeColumn) { arr.push(col); }*/
            if(includeColumn) arr.push(col);
            return arr;
        }

        const prevCol = arr.length > 0 ? arr[arr.length - 1] : null;

        const group = getGroup(props.columns??props.gridRef.dataColumns.columns, col.parentGroupId);

        if (!prevCol || prevCol.groupId !== group.groupId) {
            arr.push(new DataColumnGroup(group))
        }
        col.hideFromChooser = !includeColumn;

        return arr;
    }, []) ?? []).filter(columnPredicate);
});

function getGroup(pColumns, pGroupId) {
    const vGroup = props.columns ? props.dataColumns.getGroup(0, pGroupId) : props.gridRef.dataColumns.getGroup(0, pGroupId);
    return {
        children:pColumns.filter(x=>x.parentGroupId == pGroupId),
        groupId:vGroup?.groupId,
        headerName:vGroup?.headerName,
    }
};

const columns = props.columns ? toRef(props, 'columns') : ref(props.gridRef.dataColumns.columns);

// Make sure all columns have colId
columns.value.forEach(col => {
    if (col.colId == null) {
        const appendColId = (name, iteration = 0) => {
            if (iteration > columns.value.length) {
                return;
            }
            const combinedName = iteration > 0
                ? `${name}_${iteration}`
                : name;
            const exists = columns.value.some(x => x.colId === combinedName);
            if (exists) {
                appendColId(name, iteration + 1);
            } else {
                col.colId = combinedName;
            }
        }

        appendColId(col.name);
    }
});

const columnsList = ref(columns.value.filter(columnPredicate));
const getColumns = () => {
    columns.value.filter(columnPredicate).forEach(col => delete col._column_chooser);
    if (highlightSearch.value) {
        columnsList.value = columns.value.filter(columnPredicate);
        if (search.value.length > 0) {
            columnsList.value.filter(x => (x.caption ?? x.name).toLowerCase().includes(search.value.toLowerCase())).forEach(col => col._column_chooser = true);
        }
    } else {
        if (search.value.length > 0) {
            columnsList.value = columns.value.filter(columnPredicate).filter(x => (x.caption ?? x.name).toLowerCase().includes(search.value.toLowerCase()));
        } else {
            columnsList.value = columns.value.filter(columnPredicate);
        }
    }
}

const hasLeftPinned = computed(() => {
    return columnsList.value.some(col => col.pinned === 'left')
});
const hasRightPinned = computed(() => {
    return columnsList.value.some(col => col.pinned === 'right');
});

const allColumnsSelected = computed(() => {
    return columnsList.value.every(col => col.shown);
});

const someColumnsSelected = computed(() => {
    return columnsList.value.some(col => col.shown);
});

const selectAllColumns = computed({
    get() {
        return allColumnsSelected.value;
    },
    set(value) {
        chooseAllColumns(value);
    }
});

const previousColumnsStates = ref([]);

if (props.watchTarget) {
    watch(() => props.watchTarget, () => { getColumns(); });
}


function resetToPreviousState() {
    columnsList.value.forEach((col, index) => {
        col.shown = previousColumnsStates.value[index];
    });
    previousColumnsStates.value = [];
}

function chooseAllColumns(value = true) {
    previousColumnsStates.value = [];
    columnsList.value.forEach(col => {
        previousColumnsStates.value.push(col.shown);
        col.shown = value;
    });
}

function forceUpdate() {
    columnsList.value = [];
    window.requestAnimationFrame(() => {
        getColumns();
    });
}

function setSearchValue(value) {
    search.value = value;
    getColumns();
}

const control = {
    allColumnsSelected,
    someColumnsSelected,
    chooseAllColumns,
    forceUpdate
};

function moveColumn(pEvent, pPin) {
    if (props.gridRef) {
        const column = props.gridRef.dataColumns.getColumn(pEvent.item.getAttribute('o365-column-chooser-field'));
        let offset = 0;
        if (pPin == 'left') {
            offset += 2;
        } else if (pPin == 'right') {
            offset += props.gridRef.dataColumns.leftColumns.length + props.gridRef.dataColumns.centerColumns.length;
            if (column.pinned != 'right') {
                offset -= 1;
            }
        } else {
            offset += props.gridRef.dataColumns.leftColumns.length;
            if (column.pinned == 'left') {
                offset -= 1;
            }
        }
        props.gridRef.dataColumns.setColumnOrder(column, pEvent.newIndex + offset);
        if (column.pinned != pPin) {
            const originalPin = column.pinned;
            column.pinned = pPin;
            props.gridRef.dataColumns.updateColumnArrays();
            let shouldForceUpdate = false;
            if (originalPin == 'left' && !hasLeftPinned.value) {
                shouldForceUpdate = true;
            } else if (originalPin == 'right' &&  !hasRightPinned.value) {
                shouldForceUpdate = true;
            }

            if (shouldForceUpdate) {
                window.setTimeout(() => {
                    forceUpdate();
                }, 100)
            }
        }
    } else {
        const colName = pEvent.item.getAttribute('o365-column-chooser-field');
        const column = columns.value.find(x => x.name === colName);
        const columnIndex = columns.value.indexOf(column);
        columns.value.splice(columnIndex, 1);
        const offset =  columnIndex < pEvent.newIndex ? -1 : 0;
        columns.value.splice(pEvent.newIndex + offset, 0, column);
    }
}

function getSortable(pEl, pPin) {
    if (pEl == null) { return undefined; }
    return new Sortable(pEl, {
        group: props.gridRef
            ? {
                name: `${props.gridRef.id}-column-chooser`,
            }
            : undefined,
        sort: true,
        ghostClass: 'column-chooser-placeholder',
        draggable: '.column-chooser-item',
        setData: (pDataTransfer, pEl) => {
            const colId = pEl.getAttribute('o365-column-chooser-field');
            if (colId) {
                pDataTransfer.setData('o365-nt/group-by-column', JSON.stringify({ colId: colId }))
                if (props.gridRef && props.gridRef.hasNodeData) {
                    props.gridRef.nodeData.groupByContainerField = colId;
                }
            }
        },
        onUpdate: (pEvent) => {
            moveColumn(pEvent, pPin);
        },
        onAdd: (pEvent) => {
            moveColumn(pEvent, pPin);
        },
    });
}

function getTitle(column) {
    const mainTitle = column.headerTitle ?? column.caption ?? column.field;
    const isRequired = column.required ? ' (required)' : '';
    return mainTitle + isRequired;
}

defineExpose({ setSearchValue, control });
</script>
<script>
    export default {
        name: 'OColumnChooser'
    }
</script>

<style scoped>

.column-chooser-item, .column-chooser-item-pinned{
    display:inline-block;
    width:180px;
}

.column-chooser-item label, .column-chooser-item-pinned label {
    cursor: grab;
}
.chooser-buttons{
    flex:0 0 40px;
}

.column-chooser-item.result, .column-chooser-item-pinned.result {
    background-color: rgba(var(--bs-success-rgb), .25);
}

.column-chooser-placeholder {
    background-color: rgba(var(--bs-primary-rgb), .8);
    color: var(--bs-white);
}
</style>
